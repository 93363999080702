import React from "react";
import { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "../../Assets/css/about.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AbtUnion from "../../Assets/img/Union.svg";
import AbtInnovation from "../../Assets/img/innovation.svg";
import AbtLeft from "../../Assets/img/abt-left.webp";
import AbtRight from "../../Assets/img/abt-right.webp";
import AbtRpn from "../../Assets/img/rpneed.png";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-page">
      <Helmet>
        <title>About Us | DALL-E Free</title>
        <meta
          name="description"
          content="Experience your creative spirit with prompt AI-generated images. With DALL-E you can generate limitless images through Dall-e AI image generator"
        ></meta>
        <link rel="canonical" to="https://dall-efree.com/about-us" />
      </Helmet>
      {/*  start abtp-wrapper */}
      <div className="abtp-wrapper">
        <section className="abtp-top-section">
          <Container className="pt-5">
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="abtp-heading text-center">
                  <h1>
                    Dall-E Free Your Gateway
                    <br />
                    to Creative Imagining
                  </h1>
                  <p>
                    Welcome to Dall-E Free
                    platform that empowers users to create images effortlessly
                    through simple prompts.
                  </p>
                  <Image src={AbtUnion} alt="About Us" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="abtp-img-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="abtp-img-inner">
                  <div className="abtp-img-left">
                    <Image src={AbtLeft} alt="About Us" />
                  </div>
                  <div className="abtp-img-right">
                    <Image src={AbtRight} alt="About Us" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="abtp-ov-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="abtp-ov-inner">
                  <div className="abtp-ov-left">
                    <h2> Our Vision </h2>
                  </div>
                  <div className="abtp-ov-right">
                    <p>
                      At Dall-E Free, our vision is to provide a seamless and
                      cost-effective solution for users to bring their ideas to
                      life through captivating images. We understand the power
                      of visual storytelling and its impact on your projects,
                      and that's why we've built a platform that makes image
                      creation accessible to everyone.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="abtp-innovation-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="abtp-innovation-inner">
                  <h3 className="abt-subhead">Innovative Image Generation</h3>
                  <p className="font-24">
                    Dall-E Free is a platform Powered by DreamVibe Ai Studio
                    that utilises of OpenAI's DALL-E 2 DALL-E 3, API to produce
                    visually stunning images from simple prompts. Our platform
                    combines various techniques to ensure that you get the best
                    value for your investment. We understand that creativity
                    should not be limited by high costs, and that's why we have
                    created a solution that brings your imagination to life
                    without putting a strain on your budget.
                  </p>
                  <Image src={AbtInnovation} alt="About Us" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="abtp-rpn-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={6}>
                <Image src={AbtRpn} alt="About Us" />
              </Col>
              <Col lg={6}>
                <div className="abtp-rpn-desc">
                  <h3 className="abt-subhead">
                    Reasonable Plans for Every Need
                  </h3>
                  <p className="font-24">
                    At Dall-E Free, we are proud to offer a variety of
                    affordable plans that are customised to meet your specific
                    needs. Whether you are a freelancer, a small business owner,
                    or an individual with a passion for visual storytelling, we
                    have a plan that will suit you. Our pricing is transparent,
                    so there are no hidden fees or surprises. You'll know
                    exactly what you're getting when you sign up with us.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="abtp-js-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="abtp-js-inner">
                  <h3 className="abt-subhead"> Join Us</h3>
                  <p className="font-24">
                    With over 50,000<b>+</b> happy customers and still counting,
                    Whether you're an individual professional, students, content
                    <br />
                    creator, startup, or an established enterprise, DALL-E AI is
                    your gateway to transformative creativity.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="abtp-pop-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="abtp-pop-inner">
                  <h3 className="abt-subhead"> Privacy Is Our Priority</h3>
                  <p className="font-24">
                    Your data is sacred to us. We understand the importance of
                    privacy, and that's why every piece of data you create on
                    Dall-E Free remains exclusively yours. We take rigorous
                    measures to safeguard your information, ensuring a secure
                    and confidential environment for your creative endeavours.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="abtp-innovation-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="abtp-innovation-inner">
                  <h3 className="abt-subhead">Fueling Your Dream Projects</h3>
                  <p className="font-24">
                    At Dall-E Free, we understand the immense impact that images
                    can have on your creative projects. That's why we've created
                    a platform that is dedicated to helping you express your
                    ideas and bring your projects to life. Whether you're a
                    marketer, designer, student, or simply a hobbyist, I
                    designed our platform to inspire your imagination and turn
                    your concepts into stunning visual experiences. We believe
                    that everyone has a unique story to tell, and we're here to
                    help you share yours with the world. to fuel your
                    imagination and turn your ideas into captivating visual
                    experiences.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="abtp-info-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="abtp-info-inner text-center">
                  <p className="font-24">
                    If you have any questions you can contact us
                  </p>
                  <Link to="mailto:info@dall-efree.com">
                    <b>info@dall-efree.com</b>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      {/*  end of abtp-wrapper */}

      {/* <Container className="pt-5 "> */}
      {/* <Row className="align-items-center">
          <Col lg={6}>
            <div className="image-animate">
              <div className="left"></div>
              <div className="middle"></div>
              <div className="right"></div>
            </div>
          </Col>

          <Col lg={6} className="ps-lg-5 ps-3 mb-4">
            <div id="ih863">
              <h1>
                Dall-E Free Your Gateway
                <br />
                to Creative Imagining
              </h1>

              <p>
                Welcome to Dall-E Free, Powered by DreamVibe Ai Studio platform
                that empowers users to create images effortlessly through simple
                prompts.
              </p>

              <h2 className="abt_heading"> Our Vision </h2>

              <p>
                At Dall-E Free, our vision is to provide a seamless and
                cost-effective solution for users to bring their ideas to life
                through captivating images. We understand the power of visual
                storytelling and its impact on your projects, and that's why
                we've built a platform that makes image creation accessible to
                everyone.
              </p>

              <h3 className="abt_heading"> Innovative Image Generation </h3>

              <p>
                Dall-E Free is a platform Powered by DreamVibe Ai Studio that
                utilises of OpenAI's DALL-E 2 DALL-E 3, API to produce visually
                stunning images from simple prompts. Our platform combines
                various techniques to ensure that you get the best value for
                your investment. We understand that creativity should not be
                limited by high costs, and that's why we have created a solution
                that brings your imagination to life without putting a strain on
                your budget.
              </p>

              <h3 className="abt_heading"> Reasonable Plans for Every Need </h3>

              <p>
                At Dall-E Free, we are proud to offer a variety of affordable
                plans that are customised to meet your specific needs. Whether
                you are a freelancer, a small business owner, or an individual
                with a passion for visual storytelling, we have a plan that will
                suit you. Our pricing is transparent, so there are no hidden
                fees or surprises. You'll know exactly what you're getting when
                you sign up with us.
              </p>

              <p>
                Join us at Dall-E Free start creating your visual masterpieces
                today!
              </p>
            </div>
          </Col>
        </Row> */}
      {/* <Row>
          <Col lg={12} className=" ps-3 mb-4">
            <>
              <h2 class="abt_heading">Join Us</h2>
              <p>
                With over <strong>5000 +</strong> happy customers and still
                counting, Whether you're an individual professional, students,
                content creator, startup, or an established enterprise, DALL-E
                AI is your gateway to transformative creativity
              </p>
              <h2 className="abt_heading"> Privacy Is Our Priority </h2>
              <p>
                Your data is sacred to us. We understand the importance of
                privacy, and that's why every piece of data you create on Dall-E
                Free remains exclusively yours. We take rigorous measures to
                safeguard your information, ensuring a secure and confidential
                environment for your creative endeavours.
              </p>
              <h2 className="abt_heading"> Fueling Your Dream Projects</h2>
              <p>
                At Dall-E Free, we understand the immense impact that images can
                have on your creative projects. That's why we've created a
                platform that is dedicated to helping you express your ideas and
                bring your projects to life. Whether you're a marketer,
                designer, student, or simply a hobbyist, I designed our platform
                to inspire your imagination and turn your concepts into stunning
                visual experiences. We believe that everyone has a unique story
                to tell, and we're here to help you share yours with the world.
                to fuel your imagination and turn your ideas into captivating
                visual experiences.
              </p>
              <br />
              <p>If you have any questions You can contact us:</p>
              <p>
                By email:
                <Link to="mailto:info@dall-efree.com">
                  <b>info@dall-efree.com</b>
                </Link>
              </p>
              <br />
            </>
          </Col>
        </Row> */}
      {/* </Container> */}
    </div>
  );
};

export default AboutUs;
